import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../../components/Layout";
import Section from "../../components/Section";
import Container from "../../components/Container";
import Row from "../../components/Row";
import Col from "../../components/Col";
import Title from "../../components/Title";
import styled from "styled-components";
import ProjectThumbnail from "../../components/ProjectThumbnail";
import { BREAKPOINTS, theme } from "../../utils/styled_config";
import Seo from "../../components/Seo";

const Index = () => {
  const projects = useStaticQuery(graphql`
    query ProjectsQuery {
      allProjectsJson {
        edges {
          node {
            id
            name
            categories
            slug
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  formats: [AUTO, WEBP]
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Seo title="Works" />
      <Section>
        <Container>
          <Row justify="center" mb={14} mbLg={20}>
            <Col lg={10}>
              <CenterTitle size="3" lg="d4" slim="1">
                We do not just provide service and build products, we get{" "}
                <Danger>involved</Danger> and{" "}
                <Warning>build relationships</Warning>.
              </CenterTitle>
            </Col>
          </Row>
          <Row justify="center" mbLg="16">
            <Col lg={10}>
              <Row>
                {projects.allProjectsJson.edges.map((p) => (
                  <ColWorks sm={6} lg={4} key={p.node.id}>
                    <ProjectThumbnail
                      odd={true}
                      thumbnail={p.node.thumbnail}
                      title={p.node.name}
                      categories={p.node.categories}
                      link={`/works/${p.node.slug}`}
                    />
                  </ColWorks>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

const CenterTitle = styled(Title)`
  text-align: center;
`;

const Danger = styled.span`
  color: ${theme.color.danger};
`;

const Warning = styled.span`
  color: ${theme.color.warning};
`;

const ColWorks = styled(Col)`
  &:nth-child(3n + 1) {
    @media screen and (min-width: ${BREAKPOINTS.lg}) {
      padding-top: 80px;
    }
  }
  &:nth-child(3n + 3) {
    @media screen and (min-width: ${BREAKPOINTS.lg}) {
      padding-top: 40px;
    }
  }

  & + & {
    margin-top: 30px;

    @media screen and (min-width: ${BREAKPOINTS.lg}) {
      margin-top: 0;
    }
  }
`;

export default Index;
